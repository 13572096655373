import { render, staticRenderFns } from "./Glosary.vue?vue&type=template&id=48bcc2bc&data-app=true"
import script from "./Glosary.vue?vue&type=script&lang=js"
export * from "./Glosary.vue?vue&type=script&lang=js"
import style0 from "./Glosary.vue?vue&type=style&index=0&id=48bcc2bc&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports